import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import useCustomer from '@/views/customer/useCustomer'
import customerFilterModal from '@/modal/customerFilterModal'

export default function useCustomerOfficeList() {
  const {
    customerMenuType,
    customerMenuTypeVI,
    customerListMenuType,
    //
    exportExcel,
    deleteCustomer,
  } = useCustomer()

  const filter = customerFilterModal()
  const userData = computed(() => store.state.app.userData)
  const customers = ref([])
  const currentCustomer = ref({})
  const created = ref(false)
  const exportExcelTime = ref(null)
  const timeFrom = ref(null)
  const timeTo = ref(null)
  const exportExcelCloseDeal = ref('')

  const districts = computed(() => store.state.app.districts)
  const wards = computed(() => store.state.app.wards)
  const streets = computed(() => store.state.app.streetsByDistricts)
  const users = computed(() => store.state.app.users)
  const routeName = computed(() => store.state.app.currentRouteName)

  const showImage160 = ref([])

  // Table customers
  const tableColumns = ref([
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'minw-80 sticky-column-left text-primary',
      thClass: 'sticky-column-left',
    },
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Tên',
      key: 'company_name',
      thClass: 'minw-190 text-primary',
    },
    {
      label: 'Giá',
      key: 'price',
    },
    {
      label: 'Diện tích',
      key: 'area',
    },
    {
      label: 'Cần chuyển vào',
      key: 'chuyen-vao',
    },
    {
      label: 'Người đăng',
      key: 'nguoi-dang',
    },
    {
      label: 'Ngày đăng',
      key: 'ngay-dang',
    },
    {
      label: 'Hành động',
      key: 'actions',
      tdClass: 'sticky-column-right text-right',
      thClass: 'sticky-column-right',
    },
  ])
  const perPage = ref(50)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const totalCustomers = ref(0)

  const buildQuery = customFilter => http.buildQuery(customFilter || Object.assign(filter, {
    page: currentPage.value - 1,
    perpage: perPage.value,
    product_type: 'office',
    user_id: customerListMenuType && customerListMenuType.value === 'list' ? filter.user_id : userData.value.id,
    currency: 'usd',
  })) || ''

  const fetchCustomers = customFilter => {
    const queryString = buildQuery(customFilter)
    return http.handle(store.dispatch('customer/fetchCustomers', queryString), res => {
      customers.value = res.data?.result || []
      totalCustomers.value = res.data?.total
    })
  }

  const exportExcelCustomerOffice = () => exportExcel(buildQuery({
    product_type: 'office',
    close_deal_type: exportExcelCloseDeal.value,
    from: new Date(timeFrom.value).toISOString(),
    to: new Date(timeTo.value).toISOString(),
    user_id: customerListMenuType.value === 'list' ? '' : userData.value.id,
  }))

  const clearFilter = initFilter => {
    Object.assign(filter, customerFilterModal(), { province_id: 1, product_type: 'office' }, initFilter)
  }

  watch([currentPage, perPage], () => {
    fetchCustomers()
  })

  watch(exportExcelTime, n => {
    const today = new Date()
    if (!n) {
      timeFrom.value = null
      timeTo.value = null
    } else if (n === 'week') {
      timeFrom.value = new Date(today.getTime() - 7 * 24 * 60 * 60000)
      timeTo.value = new Date(today)
    } else if (n === 'month') {
      timeFrom.value = new Date(today.getTime() - 30 * 24 * 60 * 60000)
      timeTo.value = new Date(today)
    }
  })

  watch(() => filter.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n }).then(res => {
      const arr = res.data?.data || []
      store.dispatch('app/fetchStreetsByDistricts', http.buildQuery({ district_ids: arr.map(item => item.id).join(',') }))
    })
    if (created.value) {
      filter.district_id = ''
      filter.street_id = ''
    }
  })
  watch(() => filter.district_id, n => {
    if (!n || !filter.province_id) return
    store.dispatch('app/fetchWards', { province_id: filter.province_id, district_id: n })
    if (created.value) {
      filter.ward_id = ''
    }
  })

  return {
    filter,
    showImage160,
    districts,
    wards,
    streets,
    users,
    customerMenuType,
    customerMenuTypeVI,
    totalCustomers,
    customers,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    userData,
    routeName,
    currentCustomer,
    customerListMenuType,
    created,
    timeTo,
    timeFrom,
    exportExcelTime,
    exportExcelCloseDeal,
    buildQuery,
    clearFilter,
    // api
    fetchCustomers,
    exportExcelCustomerOffice,
    deleteCustomer,
  }
}
